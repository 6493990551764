var parser = require('./parsers/parser');
var refiner = require('./refiners/refiner');


exports.mergeOptions = function(options) {

    var addedTypes = {};
    var mergedOption = {
        parsers: [],
        refiners: []
    };

    options.forEach(function (option) {

        if (option.call) {
            option = option.call();
        }

        if (option.parsers) {
            option.parsers.forEach(function (p) {
                if (!addedTypes[p.constructor]) {
                    mergedOption.parsers.push(p);
                    addedTypes[p.constructor] = true;
                }
            });
        }

        if (option.refiners) {
            option.refiners.forEach(function (r) {
                if (!addedTypes[r.constructor]) {
                    mergedOption.refiners.push(r);
                    addedTypes[r.constructor] = true;
                }
            });
        }
    });

    return mergedOption;
};


exports.commonPostProcessing = function() {
    return {
        refiners: [
            // These should be after all other refiners
            new refiner.ExtractTimezoneOffsetRefiner(),
            new refiner.ExtractTimezoneAbbrRefiner(),
            new refiner.UnlikelyFormatFilter()
        ]
    }
};


// -------------------------------------------------------------

exports.strictOption = function () {
    var strictConfig = {
        strict: true
    };

    return exports.mergeOptions([
        exports.en(strictConfig),
        exports.de(strictConfig),
        exports.pt(strictConfig),
        exports.es(strictConfig),
        exports.fr(strictConfig),
        exports.ja(strictConfig),
        exports.zh,
        exports.commonPostProcessing
    ]);
};

exports.casualOption = function () {
    return exports.mergeOptions([
        exports.en.casual,
        // Some German abbriviate overlap with common English
        exports.de({ strict: true }), 
        exports.pt.casual,
        exports.es.casual,
        exports.fr.casual,
        exports.ja.casual,
        exports.zh,
        exports.commonPostProcessing
    ]);
};

// -------------------------------------------------------------

exports.de = function(config) {
    return {
        parsers: [
            new parser.DEDeadlineFormatParser(config),
            new parser.DEMonthNameLittleEndianParser(config),
            new parser.DEMonthNameParser(config),
            new parser.DESlashDateFormatParser(config),
            new parser.DETimeAgoFormatParser(config),
            new parser.DETimeExpressionParser(config)
        ],
        refiners: [
            new refiner.OverlapRemovalRefiner(),
            new refiner.ForwardDateRefiner(),
            new refiner.DEMergeDateTimeRefiner(),
            new refiner.DEMergeDateRangeRefiner()
        ]
    }
};

exports.de.casual = function() {
    var option = exports.de({
        strict: false
    });
    option.parsers.unshift(new parser.DECasualDateParser());
    option.parsers.unshift(new parser.DEWeekdayParser());
    return option;
};



// -------------------------------------------------------------


exports.en = function(config) {
    return {
        parsers: [
            new parser.ENISOFormatParser(config),
            new parser.ENDeadlineFormatParser(config),
            new parser.ENMonthNameLittleEndianParser(config),
            new parser.ENMonthNameMiddleEndianParser(config),
            new parser.ENMonthNameParser(config),
            new parser.ENSlashDateFormatParser(config),
            new parser.ENSlashDateFormatStartWithYearParser(config),
            new parser.ENSlashMonthFormatParser(config),
            new parser.ENTimeAgoFormatParser(config),
            new parser.ENTimeLaterFormatParser(config),
            new parser.ENTimeExpressionParser(config)
        ],
        refiners: [
            new refiner.OverlapRemovalRefiner(),
            new refiner.ForwardDateRefiner(),

            // English
            new refiner.ENMergeDateTimeRefiner(),
            new refiner.ENMergeDateRangeRefiner(),
            new refiner.ENPrioritizeSpecificDateRefiner()
        ]
    }
};

exports.en.casual = function(config) {
    config = config || {};
    config.strict = false;
    var option = exports.en(config);

    // en
    option.parsers.unshift(new parser.ENCasualDateParser());
    option.parsers.unshift(new parser.ENCasualTimeParser());
    option.parsers.unshift(new parser.ENWeekdayParser());
    option.parsers.unshift(new parser.ENRelativeDateFormatParser());
    return option;
};


exports.en_GB = function(config) {
    config = config || {};
    config.littleEndian = true;
    return exports.en(config);
}

exports.en_GB.casual = function(config) {
    config = config || {};
    config.littleEndian = true;
    return exports.en.casual(config);
}

// -------------------------------------------------------------

exports.ja = function() {
    return {
        parsers: [
            new parser.JPStandardParser()
        ],
        refiners: [
            new refiner.OverlapRemovalRefiner(),
            new refiner.ForwardDateRefiner(),
            new refiner.JPMergeDateRangeRefiner()
        ]
    }
};

exports.ja.casual = function() {
    var option = exports.ja();
    option.parsers.unshift(new parser.JPCasualDateParser());
    return option;
};

// -------------------------------------------------------------


exports.pt = function(config) {
    return {
        parsers: [
            new parser.PTTimeAgoFormatParser(config),
            new parser.PTDeadlineFormatParser(config),
            new parser.PTTimeExpressionParser(config),
            new parser.PTMonthNameLittleEndianParser(config),
            new parser.PTSlashDateFormatParser(config)
        ],
        refiners: [
            new refiner.OverlapRemovalRefiner(),
            new refiner.ForwardDateRefiner()
        ]
    }
};

exports.pt.casual = function() {
    var option = exports.pt({ 
        strict: false 
    });

    option.parsers.unshift(new parser.PTCasualDateParser());
    option.parsers.unshift(new parser.PTWeekdayParser());
    return option;
};

// -------------------------------------------------------------


exports.es = function(config) {
    return {
        parsers: [
            new parser.ESTimeAgoFormatParser(config),
            new parser.ESDeadlineFormatParser(config),
            new parser.ESTimeExpressionParser(config),
            new parser.ESMonthNameLittleEndianParser(config),
            new parser.ESSlashDateFormatParser(config)
        ],
        refiners: [
            new refiner.OverlapRemovalRefiner(),
            new refiner.ForwardDateRefiner()
        ]
    }
};

exports.es.casual = function() {
    var option = exports.es({ 
        strict: false 
    });

    option.parsers.unshift(new parser.ESCasualDateParser());
    option.parsers.unshift(new parser.ESWeekdayParser());
    return option;
};


// -------------------------------------------------------------

exports.fr = function(config) {
    return {
        parsers: [
            new parser.FRDeadlineFormatParser(config),
            new parser.FRMonthNameLittleEndianParser(config),
            new parser.FRSlashDateFormatParser(config),
            new parser.FRTimeAgoFormatParser(config),
            new parser.FRTimeExpressionParser(config)
        ],
        refiners: [
            new refiner.OverlapRemovalRefiner(),
            new refiner.ForwardDateRefiner(),
            new refiner.FRMergeDateRangeRefiner(),
            new refiner.FRMergeDateTimeRefiner()
        ]
    }
};

exports.fr.casual = function() {
    var option = exports.fr({
        strict: false
    });

    option.parsers.unshift(new parser.FRCasualDateParser());
    option.parsers.unshift(new parser.FRWeekdayParser());
    option.parsers.unshift(new parser.FRRelativeDateFormatParser());
    return option;
};


// -------------------------------------------------------------

exports.zh = function() {
    return {
        parsers: [
            new parser.ZHHantDateParser(),
            new parser.ZHHantWeekdayParser(),
            new parser.ZHHantTimeExpressionParser(),
            new parser.ZHHantCasualDateParser(),
            new parser.ZHHantDeadlineFormatParser()
        ],
        refiners: [
            new refiner.OverlapRemovalRefiner(),
            new refiner.ForwardDateRefiner()
        ]
    }
};