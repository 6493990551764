const dayjs = require('dayjs');
var Parser = require('../parser').Parser;
var ParsedResult = require('../../result').ParsedResult;
var util  = require('../../utils/DE');

var PATTERN = new RegExp('' +
    '(\\W|^)vor\\s*' +
    '(' + util.INTEGER_WORDS_PATTERN + '|[0-9]+|einigen|eine[rm]\\s*halben|eine[rm])\\s*' +
    '(sekunden?|min(?:ute)?n?|stunden?|wochen?|tag(?:en)?|monat(?:en)?|jahr(?:en)?)\\s*' +
    '(?=(?:\\W|$))', 'i');

var STRICT_PATTERN = new RegExp('' +
    '(\\W|^)vor\\s*' +
    '([0-9]+|eine(?:r|m))\\s*' +
    '(sekunden?|minuten?|stunden?|tag(?:en)?)' +
    '(?=(?:\\W|$))', 'i');

exports.Parser = function DETimeAgoFormatParser(){
    Parser.apply(this, arguments);

    this.pattern = function() {
        return this.isStrictMode()? STRICT_PATTERN : PATTERN;
    };

    this.extract = function(text, ref, match, opt){

        if (match.index > 0 && text[match.index-1].match(/\w/)) return null;

        var text = match[0];
        text  = match[0].substr(match[1].length, match[0].length - match[1].length);
        index = match.index + match[1].length;

        var result = new ParsedResult({
            index: index,
            text: text,
            ref: ref
        });

        var num = match[2].toLowerCase() ;
        if (util.INTEGER_WORDS[num] !== undefined) {
            num = util.INTEGER_WORDS[num];
        } else if (num === 'einer' || num === 'einem') {
            num = 1;
        } else if (num === 'einigen') {
            num = 3;
        } else if (/halben/.test(num)) {
            num = 0.5;
        } else {
            num = parseInt(num);
        }

        var date = dayjs(ref);

        if (/stunde|min|sekunde/i.test(match[3])) {
            if (/stunde/i.test(match[3])) {

                date = date.add(-num, 'hour');

            } else if (/min/i.test(match[3])) {

                date = date.add(-num, 'minute');

            } else if (/sekunde/i.test(match[3])) {

                date = date.add(-num, 'second');
            }

            result.start.imply('day', date.date());
            result.start.imply('month', date.month() + 1);
            result.start.imply('year', date.year());
            result.start.assign('hour', date.hour());
            result.start.assign('minute', date.minute());
            result.start.assign('second', date.second());
            result.tags['DETimeAgoFormatParser'] = true;
            return result;
        }

        if (/woche/i.test(match[3])) {
            date = date.add(-num, 'week');

            result.start.imply('day', date.date());
            result.start.imply('month', date.month() + 1);
            result.start.imply('year', date.year());
            result.start.imply('weekday', date.day());
            return result;
        }

        if (/tag/i.test(match[3])) {
            date = date.add(-num, 'd');
        }

        if (/monat/i.test(match[3])) {
            date = date.add(-num, 'month');
        }

        if (/jahr/i.test(match[3])) {

            date = date.add(-num, 'year');
        }

        result.start.assign('day', date.date());
        result.start.assign('month', date.month() + 1);
        result.start.assign('year', date.year());
        return result;

    };
}
